import { useGetCompanyList } from '@/config/organization/company/client';
import { cn } from '@/lib/utils';
import { useEffect, useState } from 'react';
import { Pagination } from '../pagination';
import type { GlobalFilterItem } from './global-filter-modal';
import { CompanyConfig, type Company } from '@pigello/pigello-matrix';
import { useGetCount } from '@/requests/hooks';
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltip';
import { Skeleton } from '../ui/skeleton';
import {
  GlobalFilerListItemSkeleton,
  GlobalFilterListItem,
} from './filter-list-item';

type OnToggle = (id: string, displayString: string) => void;

const FilterCompanyItem = ({
  company,
  isSelected,
  onToggle,
}: {
  company: Company;
  isSelected: boolean;
  onToggle: OnToggle;
}) => {
  const { data: count, isPending } = useGetCount({
    modelName: 'realestate',
    filters: {
      owned_by: {
        noop: company.id,
      },
    },
  });

  const hasNoRealEstates = count === 0;

  if (hasNoRealEstates)
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <GlobalFilterListItem
            item={company}
            onToggle={onToggle}
            isSelected={isSelected}
          >
            <p className='mt-1 text-xs font-medium'>
              {CompanyConfig.fields.orgNo.verboseName}: {company.orgNo}
            </p>
            {isPending ? (
              <Skeleton className='mt-1.5 h-3.5 w-32' />
            ) : (
              <div
                className={cn('mt-1 text-xs ', {
                  'text-foreground/80': hasNoRealEstates,
                })}
              >
                {count} fastighet(er)
              </div>
            )}
          </GlobalFilterListItem>
        </TooltipTrigger>
        <TooltipContent>
          Det är inte möjligt att filtrera på bolag som saknar fastigheter
        </TooltipContent>
      </Tooltip>
    );

  return (
    <GlobalFilterListItem
      item={company}
      onToggle={onToggle}
      isSelected={isSelected}
    >
      <p className='mt-1 text-xs font-medium'>
        {CompanyConfig.fields.orgNo.verboseName}: {company.orgNo}
      </p>
      {isPending ? (
        <Skeleton className='mt-1.5 h-3.5 w-32' />
      ) : (
        <div
          className={cn('mt-1 text-xs ', {
            'text-foreground/80': hasNoRealEstates,
          })}
        >
          {count} fastighet(er)
        </div>
      )}
    </GlobalFilterListItem>
  );
};

export default function FilterCompanies({
  searchValue,
  onToggle,
  filteredItems,
}: {
  searchValue: string;
  onToggle: OnToggle;
  filteredItems: GlobalFilterItem[];
}) {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [searchValue]);

  const { data } = useGetCompanyList({
    queryParams: {
      page,
      pageSize: 10,
      search: searchValue,
      skipGlobalFilters: true,
    },
  });

  if (!data?.list)
    return Array.from({ length: 10 }).map((_, index) => (
      <GlobalFilerListItemSkeleton key={index}>
        <Skeleton className='h-3 w-40' />
        <Skeleton className='h-3 w-32' />
      </GlobalFilerListItemSkeleton>
    ));

  return (
    <div>
      {data.list.map((company) => {
        const isSelected = filteredItems.find(
          (fi) => fi.type === 'global_companies' && fi.id === company.id
        );

        return (
          <FilterCompanyItem
            company={company}
            isSelected={!!isSelected}
            onToggle={onToggle}
            key={company.id}
          />
        );
      })}

      {data.meta.page_amount > 1 && (
        <div className='p-2'>
          <Pagination {...{ page, setPage, meta: data.meta }} />
        </div>
      )}
    </div>
  );
}
