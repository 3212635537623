import { useMenu } from '@/hooks/useMenu';
import { useManyPerms, usePerms } from '@/hooks/usePerms';
import { cn, raise } from '@/lib/utils';
import { AnimatePresence } from 'framer-motion';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import type { ISubMenu } from '../data';
import NotificationIndicator from './notification-indicator';

export function SubMenuItem({
  subMenu,
  notifications,
}: {
  subMenu: ISubMenu;
  notifications?: Record<string, number | undefined | boolean>;
}) {
  const pathname = usePathname();
  const { isEditMode, sidebarSettings } = useMenu();
  const modelNames = subMenu.modelNames ?? [];
  const { perms } = useManyPerms(modelNames);
  const { canView } = usePerms(subMenu.modelName);
  const subIsActive =
    subMenu.path &&
    pathname.endsWith(subMenu.path) &&
    ((subMenu.path.includes('contract') && pathname.includes('contract')) ||
      (!subMenu.path.includes('contract') && !pathname.includes('contract')));

  const hasNoPerms = perms.every((p) => !p.canView);
  const hasNotifications =
    notifications && notifications?.[subMenu.key] != null;

  if ((hasNoPerms && modelNames?.length > 0) || (!canView && subMenu.modelName))
    return null;

  if (!subMenu.path) raise('SubMenu path is undefined in SidebarMenuItem');
  return (
    <Link
      prefetch={false}
      key={subMenu.title}
      className={cn(
        'w-full',
        isEditMode && 'pointer-events-none',
        subMenu.disabled && 'pointer-events-none cursor-default opacity-50'
      )}
      href={subMenu.path}
    >
      <li
        className={cn(
          'my-0.5 list-none truncate',
          isEditMode && 'pl-0',
          sidebarSettings.menuVisibility?.[subMenu.key] && 'opacity-50'
        )}
      >
        <div
          className={cn(
            'flex w-full shrink items-center gap-2 truncate rounded px-2 py-1 text-sm text-foreground transition-colors',
            !isEditMode && 'hover:bg-muted-foreground/10',
            subIsActive && 'bg-accent'
          )}
        >
          <div
            className={cn(
              !pathname.includes('settings') &&
                'size-2 rounded-full bg-foreground/30',
              subIsActive && 'bg-primary'
            )}
          />
          {subMenu.title}
          <AnimatePresence>
            {hasNotifications && (
              <NotificationIndicator
                count={notifications[subMenu.key]}
                noCheck
                noZero
              />
            )}
          </AnimatePresence>
        </div>
      </li>
    </Link>
  );
}
