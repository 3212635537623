 import type { Notification } from '@pigello/pigello-matrix';

import { NotificationConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, mutateGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { mutateGenericInstance } from '@/requests/mutating';

export const mutateNotification = async (
  options: mutateGenericInstanceProps<Notification>
) => {
  return mutateGenericInstance<Notification>({
    ...options,
    config: NotificationConfig,
  });
};

export const useMutateNotification = (
  options: UseMutationOptions<Notification, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useMutateGenericInstance<Notification>(mutateNotification, options, 'notification');