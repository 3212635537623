import { Icons } from '@/components/icons';
import { cn } from '@/lib/utils';
import { CheckIcon, XMarkIcon } from '@heroicons/react/16/solid';
import { motion } from 'framer-motion';

export default function NotificationIndicator({
  count,
  className,
  noCheck,
  noZero,
  error,
}: {
  count?: number | string | boolean;
  className?: string;
  noCheck?: boolean;
  noZero?: boolean;
  error?: boolean;
}) {
  if (error)
    return (
      <div
        className={cn(
          'ml-auto rounded-full bg-red-200 px-2 text-xs text-red-500',
          className
        )}
      >
        <XMarkIcon className='size-4' />
      </div>
    );
  if (count == 0 && noZero) return null;

  if (count == 0 && !noCheck) {
    return (
      <div
        className={cn(
          'ml-auto rounded-full bg-green-200 px-2 py-px text-xs text-green-800',
          className
        )}
      >
        <CheckIcon className='size-4' />
      </div>
    );
  }
  return (
    <motion.div
      key={`count`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, y: -5 }}
      className={cn(
        'ml-auto flex min-w-[32px] items-center justify-center rounded-full bg-primary px-2 py-px text-xs text-primary-foreground',
        className
      )}
    >
      {count === true ? (
        <Icons.loader className='size-4 animate-spin' />
      ) : (
        <motion.div
          initial={{ opacity: 0, y: -5 }}
          animate={{ opacity: 1, y: 0 }}
        >
          {count}
        </motion.div>
      )}
    </motion.div>
  );
}
