'use client';

import useGetPendingSignings from '@/app/dashboard/inbox/pending-signings/components/use-get-pending-signings';
import { useGetMonitoringConfigurationList } from '@/config/monitoring/configurations/monitoringconfiguration/client';
import { getCount } from '@/requests/api/get-count';
import type {
  BaseInstance,
  ContentType,
  ModelName,
  MonitoringConfig,
} from '@pigello/pigello-matrix';
import {
  AllMonitoringConfigs,
  AutomaticDistributionQuotaConfig,
  RetroactiveApartmentRevenueProposalConfig,
  RetroactiveIndustrialPremisesRevenueProposalConfig,
  RetroactiveOutdoorSectionRevenueProposalConfig,
  RetroactiveParkingSpotRevenueProposalConfig,
} from '@pigello/pigello-matrix';
import { useQueries } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useManyPerms } from './usePerms';

type IPendingDistributionQuotaKind =
  | 'apartment'
  | 'industrial_premises'
  | 'outdoor_section'
  | 'parking_spot'
  | 'brf_apartment';

export default function useMonitoringCounts<Instance extends BaseInstance>() {
  const { count: pendingSigningCounts, loading: pendingSigningCountsLoading } =
    useGetPendingSignings({ countOnly: true });

  const { data: monitoringConfigs, isLoading } =
    useGetMonitoringConfigurationList({
      queryParams: {
        page: 1,
        pageSize: 50,
      },
    });

  const contentTypes = useMemo(
    () =>
      monitoringConfigs?.list?.map((mc) => mc.forContentType.id as ContentType),
    [monitoringConfigs]
  );

  const fetchApprovalCounts = async (
    currentConfig: MonitoringConfig<Instance>
  ) => {
    const res = await getCount({
      modelName: currentConfig.modelName,
      filters: {
        is_relevant_for_approving: { noop: true },
        [`${currentConfig?.fields.mtApprovedTime.externalFieldName}`]: {
          __isnull: true,
        },
        [`${currentConfig?.fields.mtDeniedTime.externalFieldName}`]: {
          __isnull: true,
        },
      },
    });

    return {
      count: res,
      modelName: currentConfig.modelName,
    };
  };
  const fetchPerformCounts = async (
    currentConfig: MonitoringConfig<Instance>
  ) => {
    const res = await getCount({
      modelName: currentConfig.modelName,
      overrideUrl: `monitoring/tracked/${currentConfig.modelName}/perform`,
      overrideUrlReplaceAll: true,
    });

    return {
      count: res,
      modelName: currentConfig.modelName,
    };
  };

  const fetchRetroactivityCounts = async (
    config:
      | typeof RetroactiveApartmentRevenueProposalConfig
      | typeof RetroactiveIndustrialPremisesRevenueProposalConfig
      | typeof RetroactiveParkingSpotRevenueProposalConfig
      | typeof RetroactiveOutdoorSectionRevenueProposalConfig
  ) => {
    const res = await getCount({
      modelName: config.modelName,
      filters: {
        accepted: { __isnull: true },
      },
    });
    return {
      count: res,
      config,
    };
  };

  const fetchPendingDistributionQuotaCounts = async (
    querykey: IPendingDistributionQuotaKind
  ) => {
    const res = await getCount({
      modelName: AutomaticDistributionQuotaConfig.modelName,
      filters: {
        accepted: { __isnull: true },
        hidden: { noop: false },
        [`${querykey}`]: { '__isnull!': true },
      },
    });
    return res;
  };

  const configs = useMemo(() => {
    return Object.values(AllMonitoringConfigs ?? {}).filter((c) =>
      contentTypes?.includes(c.parentContentType as ContentType)
    );
  }, [contentTypes]);

  const { perms } = useManyPerms(
    configs.map((c) => c.parentConfig as ModelName)
  );

  const relevantConfigs = configs.filter(
    (c) => perms.find((p) => p.modelName === c.parentConfig)?.canView
  );

  const approvalResults = useQueries({
    queries: monitoringConfigs
      ? relevantConfigs.map((mc: MonitoringConfig<any>) => ({
          queryKey: ['monitoringcounts', mc.modelName],
          queryFn: () => fetchApprovalCounts(mc),
          staleTime: 60 * 5 * 1000,
          enabled: !!relevantConfigs.length && !isLoading,
        }))
      : [],
    combine: (results) => {
      const perModelName: Partial<Record<ModelName, number | undefined>> = {};

      results.forEach((r) => {
        if (r.data?.modelName) {
          perModelName[r.data?.modelName as ModelName] = r.data?.count;
        }
      });

      return {
        data: results.reduce((acc, cur) => acc + (cur.data?.count ?? 0), 0),
        perModelName,
        pending: results.some((result) => result.isPending),
      };
    },
  });

  const performResults = useQueries({
    queries: monitoringConfigs
      ? relevantConfigs.map((mc: MonitoringConfig<any>) => ({
          queryKey: ['performcounts', mc.modelName],
          queryFn: () => fetchPerformCounts(mc),
          staleTime: 60 * 5 * 1000,
          enabled: !!relevantConfigs.length && !isLoading,
        }))
      : [],
    combine: (results) => {
      const perModelName: Partial<Record<ModelName, number | undefined>> = {};

      results.forEach((r) => {
        if (r.data?.modelName) {
          perModelName[r.data?.modelName as ModelName] = r.data?.count;
        }
      });

      return {
        data: results.reduce((acc, cur) => acc + (cur.data?.count ?? 0), 0),
        perModelName,
        pending: results.some((result) => result.isPending),
      };
    },
  });
  const { perms: retroActivityPerms, isFetchingPerms } = useManyPerms([
    'retroactiveapartmentrevenueproposal',
    'retroactiveindustrialpremisesrevenueproposal',
    'retroactiveoutdoorsectionrevenueproposal',
    'retroactiveparkingspotrevenueproposal',
  ]);
  const queries = [
    RetroactiveApartmentRevenueProposalConfig,
    RetroactiveIndustrialPremisesRevenueProposalConfig,
    RetroactiveParkingSpotRevenueProposalConfig,
    RetroactiveOutdoorSectionRevenueProposalConfig,
  ].filter(
    (config) =>
      retroActivityPerms?.find((p) => p.modelName === config.modelName)?.canView
  );

  const retroactivityResults = useQueries({
    queries: queries.map((p) => ({
      queryKey: ['retroactivitycounts', p.modelName],
      queryFn: () => fetchRetroactivityCounts(p),
      staleTime: 60 * 5 * 1000,
      enabled: queries.length > 0 && !isFetchingPerms,
    })),
  });

  const pendingDistributionResults = useQueries({
    queries: (
      [
        'apartment',
        'industrial_premises',
        'outdoor_section',
        'parking_spot',
        'brf_apartment',
      ] as IPendingDistributionQuotaKind[]
    ).map((p) => ({
      queryKey: ['retroactivitycounts', p],
      queryFn: () => fetchPendingDistributionQuotaCounts(p),
      staleTime: 60 * 5 * 1000,
      enabled: true,
    })),
  });

  const [
    pendingApartmentQuotas,
    pendingIndustrialPremisesQuotas,
    pendingOutdoorSectionQuotas,
    pendingParkingSpotQuotas,
    pendingBrfApartmentQuotas,
  ] = pendingDistributionResults.map((r) => r.data ?? 0);

  const totalApprovalCount = approvalResults?.data;
  const approvalCountLoading = approvalResults.pending;

  const totalPerformCount = performResults?.data;

  const performCountLoading = performResults.pending;
  const totalRetroactivityAmount = retroactivityResults?.reduce(
    (acc, cur) => acc + (cur.data?.count ?? 0),
    0
  );
  const retroactivityCountLoading = retroactivityResults.some(
    (a) => a.isPending
  );

  const totalPendingDistributionQuotas = pendingDistributionResults.reduce(
    (acc, cur) => acc + (cur.data ?? 0),
    0
  );
  const pendingDistributionQuotasLoading = pendingDistributionResults.some(
    (a) => a.isPending
  );

  return {
    pendingSigningCounts,
    pendingSigningCountsLoading,
    approvalCount: totalApprovalCount,
    approvalCountPerModelName: approvalResults.perModelName,
    approvalCountLoading,
    performCount: totalPerformCount,
    performCountPerModelName: performResults.perModelName,
    performCountLoading,
    retroactivityCount: totalRetroactivityAmount,
    retroactivityCountLoading,
    pendingApartmentQuotas,
    pendingIndustrialPremisesQuotas,
    pendingOutdoorSectionQuotas,
    pendingParkingSpotQuotas,
    pendingBrfApartmentQuotas,
    totalPendingDistributionQuotas,
    pendingDistributionQuotasLoading,
    totalCount:
      totalPerformCount +
      totalApprovalCount +
      totalRetroactivityAmount +
      totalPendingDistributionQuotas +
      pendingSigningCounts,
    totalCountLoading:
      approvalCountLoading ||
      performCountLoading ||
      retroactivityCountLoading ||
      pendingSigningCountsLoading,
    retroactivityResults,
  };
}
