'use client';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Checkbox } from '@/components/ui/checkbox';
import { useGetSelf } from '@/hooks/useGetSelf';
import { useMenu } from '@/hooks/useMenu';
import { useManyPerms, usePerms } from '@/hooks/usePerms';
import { cn, raise } from '@/lib/utils';
import type { DropResult } from '@hello-pangea/dnd';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Bars3Icon } from '@heroicons/react/16/solid';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useMemo } from 'react';
import type { ISubMenu } from '../data';
import { menuData } from '../data';
import { reorder } from '../utils';
import NotificationIndicator from './notification-indicator';
import { SubMenuItem } from './sub-menu-item';
import type { CheckedState } from './types';

export function SubMenu({
  menu,
  parentKey,
  notifications,
}: {
  menu: ISubMenu;
  parentKey: string;
  notifications?: Record<string, number | boolean | undefined>;
}) {
  const { data: self } = useGetSelf();
  const isPigelloUser =
    (self?.email?.endsWith('@pigello.se') && self?.emailVerified) ?? false;
  const { isEditMode, sidebarSettings, setSidebarSettings } = useMenu();
  const pathname = usePathname();
  const modelName = useMemo(() => menu.modelName, [menu]);
  const { canView } = usePerms(modelName);
  const modelNames = menu.modelNames || [];
  const { perms } = useManyPerms(modelNames);
  const hasNoPerms =
    perms.every((perm) => !perm.canView) && modelNames.length > 0;
  const isNested = menu.subMenus && menu.subMenus?.length > 0;

  const handleDragEnd = (result: DropResult, key: string) => {
    if (!result.destination) return;
    const curr =
      sidebarSettings.menuOrder?.[key] ??
      menuData[parentKey as keyof typeof menuData].subMenus
        .find((item) => item.key === key)
        ?.subMenus?.map((item) => item.key);

    const newItems = reorder(
      curr,
      result.source.index,
      result.destination.index
    );
    setSidebarSettings((prev) => ({
      ...prev,
      menuOrder: {
        ...prev.menuOrder,
        [key]: newItems,
      },
    }));
  };

  const handleIsMenuVisible = (checked: CheckedState, subMenu: ISubMenu) => {
    const newState = {
      ...sidebarSettings.menuVisibility,
      [subMenu.key]: !checked,
    };
    if (menu.subMenus?.every((subMenu) => newState[subMenu.key])) {
      setSidebarSettings((prev) => ({
        ...prev,
        ...{ ...prev.menuVisibility, [subMenu.key]: true, [menu.key]: true },
      }));
      return;
    }
    setSidebarSettings((prev) => ({
      ...prev,
      menuVisibility: newState,
    }));
  };

  if (
    hasNoPerms ||
    (!canView && menu.modelName) ||
    menu.hidden ||
    (menu.pigelloUserOnly && !isPigelloUser)
  )
    return null;

  if (isNested) {
    const hasNotifications = notifications && notifications?.[menu.key] != null;
    return (
      <Accordion
        value={sidebarSettings.expandedItems?.[menu.key]}
        onValueChange={(values) => {
          setSidebarSettings((prev) => ({
            ...prev,
            expandedItems: {
              ...prev.expandedItems,
              [menu.key]: values,
            },
          }));
        }}
        className='w-full'
        type='multiple'
      >
        <DragDropContext onDragEnd={(e) => handleDragEnd(e, menu.key)}>
          <AccordionItem
            className='grow border-none '
            value={menu.title}
            key={menu.key}
          >
            <AccordionTrigger
              disabled={sidebarSettings.menuVisibility?.[menu.key]}
              className={cn(
                'my-0.5 flex w-full items-center justify-between gap-2 rounded px-2 py-1 text-left  text-foreground transition-colors hover:no-underline',
                sidebarSettings.menuVisibility?.[menu.key] && 'opacity-50',
                !sidebarSettings.menuVisibility?.[menu.key] &&
                  'hover:bg-muted-foreground/10'
              )}
            >
              <div className='flex w-full items-center gap-1'>
                {menu.icon}
                <div className=' flex w-full items-center justify-between truncate text-sm font-normal text-foreground'>
                  {menu.title}

                  {hasNotifications && (
                    <NotificationIndicator count={notifications[menu.key]} />
                  )}
                </div>
              </div>
            </AccordionTrigger>
            <AccordionContent className='my-1 pb-0'>
              <Droppable
                isDropDisabled={!isEditMode}
                type={menu.key}
                droppableId={menu.key}
              >
                {(provided) => (
                  <ul ref={provided.innerRef} {...provided.droppableProps}>
                    {menu.subMenus
                      ?.filter(
                        (subMenu) =>
                          !(isEditMode ? {} : sidebarSettings.menuVisibility)?.[
                            subMenu.key
                          ] &&
                          (!subMenu.modelName ||
                            perms[modelNames.indexOf(subMenu.modelName)]
                              ?.canView)
                      )
                      .sort(
                        (a, b) =>
                          sidebarSettings.menuOrder?.[menu.key]?.indexOf(
                            a.key
                          ) -
                          sidebarSettings.menuOrder?.[menu.key]?.indexOf(b.key)
                      )
                      .map((subMenu, idx) => (
                        <Draggable
                          key={subMenu.key}
                          index={idx}
                          draggableId={subMenu.key}
                          isDragDisabled={!isEditMode}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...(!isEditMode && provided.dragHandleProps)}
                              className={cn(
                                'group/item flex w-full items-start gap-1',
                                !isEditMode && 'pl-6'
                              )}
                            >
                              {isEditMode && (
                                <button
                                  {...provided.dragHandleProps}
                                  className={cn(
                                    'invisible mt-1.5 cursor-grab transition-opacity group-hover/item:visible'
                                  )}
                                >
                                  <Bars3Icon className='size-4' />
                                </button>
                              )}
                              {isEditMode && (
                                <Checkbox
                                  checked={
                                    !sidebarSettings.menuVisibility?.[
                                      subMenu.key
                                    ]
                                  }
                                  disabled={
                                    sidebarSettings.menuVisibility?.[
                                      menu.key
                                    ] === true &&
                                    sidebarSettings.menuVisibility?.[
                                      subMenu.key
                                    ]
                                  }
                                  onCheckedChange={(checked) =>
                                    handleIsMenuVisible(checked, subMenu)
                                  }
                                  className='mt-1.5 size-4'
                                />
                              )}
                              <SubMenuItem
                                key={subMenu.title + subMenu.path}
                                subMenu={subMenu}
                                notifications={notifications}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </AccordionContent>
          </AccordionItem>
        </DragDropContext>
      </Accordion>
    );
  }

  if (!menu.path)
    raise(`menu path is undefined in SidebarMenuItem ${menu.title}`);

  const isActive =
    menu.path &&
    pathname.endsWith(menu.path.split('/')[menu.path.split('/').length - 1]);

  const hasNotifications = notifications && notifications?.[menu.path] != null;

  return (
    <ul key={menu.key} className='grow'>
      <Link
        prefetch={false}
        aria-disabled={menu.disabled}
        className={cn(
          (menu.disabled || sidebarSettings.menuVisibility?.[menu.key]) &&
            'pointer-events-none opacity-50'
        )}
        href={menu.path}
      >
        <li
          className={cn(
            'my-0.5 flex w-full items-center justify-between gap-2 truncate rounded px-2 py-1 text-left text-sm text-foreground transition-colors hover:bg-muted-foreground/10 ',
            isActive && 'bg-accent'
          )}
        >
          <div className='flex w-full items-center gap-1 transition-all duration-200 active:ml-0.5'>
            {menu.icon}
            <p className='text-foreground'>{menu.title}</p>

            {hasNotifications && (
              <NotificationIndicator count={notifications[menu.path]} />
            )}
          </div>
        </li>
      </Link>
    </ul>
  );
}
