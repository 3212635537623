import { cn } from '@/lib/utils';
import type {
  Company,
  ExternalClient,
  RealEstate,
  Segment,
  TagUsage,
} from '@pigello/pigello-matrix';
import { AnimatePresence, motion } from 'framer-motion';
import {
  forwardRef,
  type FunctionComponent,
  type PropsWithChildren,
} from 'react';
import { Skeleton } from '../ui/skeleton';

const FilterTag = ({ tag }: { tag: TagUsage }) => {
  return (
    <div
      key={tag.id}
      className='flex shrink-0 items-center gap-1.5 rounded-full border border-foreground/20 px-2 py-1 align-middle text-xs text-foreground/70'
    >
      <div
        style={{
          backgroundColor: tag?.tag?.colorCode?.startsWith('#')
            ? tag.tag.colorCode
            : `#${tag?.tag?.colorCode}`,
        }}
        className='size-2.5 rounded-full'
      />
      <span>{tag.tag?.name}</span>
    </div>
  );
};

const GlobalFilterListItem = forwardRef(
  <TItem extends Segment | RealEstate | Company | ExternalClient>({
    item,
    children,
    onToggle,
    isSelected,
  }: PropsWithChildren<{
    item: TItem;
    onToggle: (id: string, name: string) => void;
    isSelected: boolean;
  }>) => {
    return (
      <button
        className={cn(
          'group flex w-full items-center justify-between border-b bg-background px-4 py-3 text-start transition-all duration-300 hover:bg-muted',
          {
            'bg-primary/5 hover:bg-primary/10': isSelected,
          }
        )}
        key={item.id}
        onClick={() => onToggle(item.id as string, item.name)}
      >
        <div className='shrink-0'>
          <div className='flex gap-2'>
            {item.customId && (
              <p className='text-sm text-foreground/50'>{item.customId}</p>
            )}
            <p className='text-sm'>{item.name}</p>
          </div>
          {children}
        </div>
        <div className='flex flex-1 flex-wrap justify-end gap-2 pr-2'>
          {item.tags.map((tag) => (
            <FilterTag tag={tag} key={tag.id} />
          ))}
        </div>
        <div className='flex items-center gap-2'>
          <div
            className={cn(
              'size-5 rounded-full border border-foreground/50 p-0.5  transition-all group-active:scale-[80%]',
              {
                'border-primary': isSelected,
              }
            )}
          >
            <AnimatePresence>
              {isSelected && (
                <motion.div
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ type: 'spring', scale: 1, opacity: 1 }}
                  exit={{ scale: [1, 1.2, 0], opacity: 0 }}
                  className='size-full rounded-full bg-primary'
                />
              )}
            </AnimatePresence>
          </div>
        </div>
      </button>
    );
  }
);

GlobalFilterListItem.displayName = 'GlobalFilterListItem';

const GlobalFilerListItemSkeleton: FunctionComponent<PropsWithChildren> = ({
  children,
}) => (
  <div className='flex items-center justify-between border-b bg-background px-4 py-3'>
    <div className='flex w-full flex-col gap-1.5'>
      <Skeleton className='h-3.5 w-48' />
      {children}
    </div>
    <div className='flex items-center gap-2'>
      <Skeleton className='h-[1.625rem] w-32 rounded-full' />
      <Skeleton className='size-5 rounded-full' />
    </div>
  </div>
);

export { GlobalFilterListItem, GlobalFilerListItemSkeleton };
