'use client';

import { Icons } from '@/components/icons';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Button, buttonVariants } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';
import { useToast } from '@/hooks/useToast';
import { cn } from '@/lib/utils';
import type { FieldValue } from '@pigello/pigello-matrix';
import { useQueryClient } from '@tanstack/react-query';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/navigation';
import { useState, type Dispatch, type SetStateAction } from 'react';
import type { RoleSegmentCombination } from '.';
import type { CheckedState } from '../types';

export function RoleSegmentCombination({
  segmentCombination,
  accordionItemOpen,
  isSideBar,
  setIsOpen,
}: {
  segmentCombination: RoleSegmentCombination;
  accordionItemOpen: string;
  isSideBar?: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToast();
  const segments = getCookie('segments')?.split(',');
  const { refresh } = useRouter();
  const queryClient = useQueryClient();
  const [choosenSegments, setChoosenSegments] = useState<
    Array<FieldValue<string>>
  >(isSideBar ? [...(segments ?? [])] : []);
  const onCheckedChange = (checked: CheckedState, id: FieldValue<string>) => {
    if (checked) {
      setChoosenSegments((prev) => [...new Set([...prev, id])]);
    } else {
      setChoosenSegments((prev) => prev.filter((seg) => seg !== id));
    }
  };
  const handleChooseSegments = (segments: FieldValue<string>[]) => {
    setIsLoading(true);
    deleteCookie('segments');
    setCookie('segments', segments?.join(','));
    if (!isSideBar) {
      refresh();
      return;
    }
    queryClient.invalidateQueries();
    queryClient.clear();
    setIsOpen?.(false);
    addToast({
      type: 'info',
      title: 'Workspace ändrad',
      message: 'Hämtar om listor...',
    });
  };

  const isSegmentCombinationChoosen = segmentCombination.segments.some((seg) =>
    segments?.includes(String(seg.id))
  );
  return (
    <AccordionItem
      className='border-none'
      value={segmentCombination.role.id ?? ''}
    >
      <Card>
        <CardContent className='grid gap-2'>
          <div className='flex items-center justify-between'>
            <h2>
              {segmentCombination.role.name}{' '}
              <span className='text-sm text-muted-foreground'>
                {isSegmentCombinationChoosen && '(vald)'}
              </span>
            </h2>
            <Button
              disabled={accordionItemOpen === segmentCombination.role.id}
              onClick={() => {
                const segments = segmentCombination.segments.map(
                  (seg) => seg.id
                );
                handleChooseSegments(segments);
              }}
              className='self-end'
              variant={'outline'}
              size={'sm'}
            >
              Välj workspace{' '}
              {isLoading && (
                <Icons.loader className='ml-2 size-4 animate-spin' />
              )}
            </Button>
          </div>

          <div className='flex w-full grow items-center justify-between'>
            <p className='w-full grow'>
              Rollen <strong>{segmentCombination.role.name}</strong> i{' '}
              <strong>{segmentCombination.segments.length}</strong> segment
            </p>
            <AccordionTrigger
              onClick={() => {
                if (
                  accordionItemOpen !== segmentCombination.role.id &&
                  !isSideBar
                ) {
                  setChoosenSegments(
                    segmentCombination.segments.map((seg) => seg.id)
                  );
                }
              }}
              className={cn(
                buttonVariants({ variant: 'link', size: 'sm' }),
                'w-auto justify-end gap-2 p-0'
              )}
            >
              Visa segment
            </AccordionTrigger>
          </div>
          <AccordionContent>
            <div className='flex flex-col gap-4 border-t pt-2'>
              {segmentCombination?.segments.map((segment) => (
                <div className='flex items-center gap-4 px-2' key={segment.id}>
                  {isSideBar ? (
                    <Checkbox
                      id={segment.id ?? ''}
                      defaultChecked={segments?.includes(String(segment.id))}
                      onCheckedChange={(checked) =>
                        onCheckedChange(checked, segment.id)
                      }
                    />
                  ) : (
                    <Checkbox
                      id={segment.id ?? ''}
                      checked={choosenSegments.includes(segment.id)}
                      onCheckedChange={(checked) =>
                        onCheckedChange(checked, segment.id)
                      }
                    />
                  )}
                  <Label htmlFor={segment.id ?? ''} className='text-sm'>
                    {segment?.name}{' '}
                    <span className='text-xs text-muted-foreground'>
                      {segments?.includes(String(segment.id)) && '(vald)'}
                    </span>
                  </Label>
                </div>
              ))}
              <Button
                disabled={choosenSegments.length === 0}
                onClick={() => {
                  handleChooseSegments(choosenSegments);
                }}
                className='self-end'
                variant={'outline'}
                size={'sm'}
              >
                Välj specifika segment{' '}
                {isLoading && (
                  <Icons.loader className='ml-2 size-4 animate-spin' />
                )}
              </Button>
            </div>
          </AccordionContent>
        </CardContent>
      </Card>
    </AccordionItem>
  );
}
